import { render, staticRenderFns } from "./indexHeader.vue?vue&type=template&id=3225e9ba&scoped=true"
import script from "./indexHeader.vue?vue&type=script&lang=js"
export * from "./indexHeader.vue?vue&type=script&lang=js"
import style0 from "./indexHeader.vue?vue&type=style&index=0&id=3225e9ba&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3225e9ba",
  null
  
)

export default component.exports